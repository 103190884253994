// URI *****************************************************************************************************************

(function () {
  if (!window.Uri) {
    // see: https://tools.ietf.org/html/rfc3986
    const regexUri = /^(([a-z][a-z0-9+.-]*):)?(\/\/(([!$&\\'()*,;=a-z0-9._~-]|%[0-9a-f][0-9a-f])*)(\:([0-9]+))?)?(([\/!$&\\'()*,;=:@a-z0-9._~-]|%[0-9a-f][0-9a-f])*)(\?([!$&\\'()*,;=:@a-z0-9._~\/?-]|%[0-9a-f][0-9a-f])*)?(\#.*)?$/i;

    const parseQuery = (params: Record<string, string|null>, raw: string) => {
        raw
          .split('&')
          .forEach(one => {
            if (one) {
              const i = one.indexOf('=');
              if (-1 === i) {
                params[decodeURIComponent(one)] = null;
              } else {
                params[decodeURIComponent(one.substring(0, i))] = decodeURIComponent(one.substring(i + 1));
              }
            }
          });
    };

    const parse = (uri: TypeOfUri, raw: string) => {
      const m = regexUri.exec(raw);
      if (m) {
        uri.scheme = m[2];
        uri.host = m[4];
        uri.path = m[8];
        uri.port = parseInt(m[7], 10) || window.Uri.defaultPorts[uri.scheme] || 0;
        uri.query = (m[10] && m[10].substr(1) || '');
        uri.fragment = (m[12] && m[12].substr(1) || '');
      }
    };

    const enc = (input: string|null) => input ? encodeURIComponent(input) : '';

    /**
     * Simple and straightforward Uri wrapper.
     */
    window.Uri = class Uri implements TypeOfUri {
      static readonly defaultPorts = {
        ftp: 21,
        http: 80,
        https: 443
      };

      /**
       * Alias for {@link window.Uri.from}.
       *
       * @deprecated Use {@link window.Uri.from} instead.
       */
       static create (uri: string|TypeOfUri): TypeOfUri {
        if (uri instanceof window.Uri) {
          return <any> uri;
        }
        return new window.Uri(<any> uri);
      }

      /**
       * Creates {@link window.Uri} form an argument.
       */
       static from (uri: string|TypeOfUri) {
        return window.Uri.create(uri);
      }

      /**
       * Gets or sets scheme of the Uri.
       */
      scheme?: string;
      /**
       * Gets or sets hostname of the Uri.
       */
      host?: string;
      /**
       * Gets or sets path component of the Uri.
       */
      path?: string;
      /**
       * Gets or sets port of the Uri.
       */
      port?: number;
      /**
       * Gets or sets fragment of the Uri.
       */
      fragment?: string;
      /**
       * Gets or sets query component of the Uri as object. Allows accessing and manipulating individual arguments
       * within query component.
       */
      queryParams!: Record<string, string|null>;

      constructor(raw: string) {
        parse(this, raw);
      }
      /**
       * Is _true_ if the wrapper Uri is relative.
       */
      get isRelative () {
        return !this.scheme;
      }
      /**
       * Is _true_ if the wrapper Uri is absolute.
       */
      get isAbsolute () {
        return !!this.scheme;
      }
      /**
       * Gets or sets authority of the Uri (i.e. hostname and port if non standard).
       */
      get authority () {
        if (this.port && (!this.scheme || this.port !== window.Uri.defaultPorts[this.scheme])) {
          return `${this.host}:${this.port}`;
        }
        return this.host;
      }

      set authority (authority: string|undefined) {
        if (!authority) {
          this.host = authority;
          this.port = 0;
        } else {
          const i = authority.indexOf(':');
          if (-1 === i) {
            this.host = authority;
            this.port = 0;
          } else {
            this.host = authority.substr(0, i);
            this.port = parseInt(authority.substr(i + 1), 10) || 0;
          }
        }
      }
      /**
       * Gets or sets the wrapped Uri.
       *
       * @type {String}
       */
      get href () {
        const query = this.query;
        const queryString = query ? `?${query}` : '';
        const fragment = this.fragment ? `#${this.fragment}` : '';
        const authority = this.authority ? `//${this.authority}` : '';
        const scheme = this.scheme ? `${this.scheme}:` : '';
        return `${scheme}${authority}${this.path}${queryString}${fragment}`;
      }
      set href (href) {
        parse(this, href);
      }
      /**
       * Gets or sets query component of the Uri. To access or manipulate individual query arguments use
       * {@link window.Uri#queryParams}.
       *
       * @type {String}
       */
      get query () {
        const queryParams = this.queryParams || {};
        return Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${enc(queryParams[key])}`)
          .join('&');
      }
      set query (query) {
        this.queryParams = {};
        parseQuery(this.queryParams, query);
      }
      toString () {
        return this.href;
      }
    };
  }
}());

// APPOINTMENT *********************************************************************************************************

window.MVM = window.MVM || {};
window.MVM.getRadioValue = (e: any): string => {
  if (e instanceof Element) {
    const checked = Array.from(e.querySelectorAll<HTMLInputElement>('input[type="radio"]')).find(input => input.checked);
    return (checked && checked.getAttribute('data-value')) || '';
  }
  return '';
};
window.MVM.updateRadioValue = (e: any, v: string): void => {
  if (e instanceof Element) {
    for (const input of Array.from(e.querySelectorAll<HTMLInputElement>('input[type="radio"]'))) {
      input.checked = input.getAttribute('data.value') === v;
    }
  }
};

// ICON ****************************************************************************************************************

window.MVM = window.MVM || {};
window.MVM.updateIcon = (el: SVGSVGElement, key: string) => {
  if (el) {
    if (el.firstChild instanceof SVGUseElement) {
      const attr = document.createAttributeNS('http://www.w3.org/1999/xlink', 'href');
      attr.value = `#${key}`;
      el.firstChild.attributes.setNamedItemNS(attr);
    } else {
      const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      el.insertBefore(use, el.firstChild);
      const attr = document.createAttributeNS('http://www.w3.org/1999/xlink', 'href');
      attr.value = `#${key}`;
      use.attributes.setNamedItemNS(attr);
    }
  }
};

// tinyMCE *************************************************************************************************************

(function () {
  const supplyId = (function() {
    let sup = 0;
    return () => {
      const seed = sup;
      sup = sup === Number.MAX_SAFE_INTEGER ? 0 : sup + 1;
      return `renopont-tinymce-connector-${seed}`;
    };
  }());

  class Interop {
    constructor(readonly dotNetRef: DotNetRef) { }

    getValue(): Promise<string> {
      return this.dotNetRef.invokeMethodAsync<string>('JsGetValueAsync');
    }

    updateValue(value: string): Promise<void> {
      return this.dotNetRef.invokeMethodAsync<void>('JsUpdateValueAsync', value);
    }

    initialize(id: string) {
      return this.dotNetRef.invokeMethodAsync<void>('JsInitializeAsync', id);
    }

    ready(id: string) {
      return this.dotNetRef.invokeMethodAsync<void>('JsReadyAsync', id);
    }

    notify(eventName: string) {
      return this.dotNetRef.invokeMethodAsync<void>('JsNotifyAsync', eventName);
    }
  }

  /*
  window.MVM.tinyMce = {
    applyBold(id: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.execCommand('bold', false);
    },
    applyItalic(id: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.execCommand('italic', false);
    },
    applyLink(id: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.execCommand('mceLink', false);
    },
    applyUnlink(id: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.execCommand('unlink', false);
    },
    applyList(id: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.execCommand('InsertUnorderedList', false, { });
    },
    async updateValue(id: string, value: string) {
      const ed = window.tinymce.get(id);
      if (!ed) {
        throw new Error(`invalid editor id ${id}`);
      }
      ed.setContent(value || '', { format: 'html' });
    },
    init: (options: Record<string, any>, dotNetRef: DotNetRef): Promise<void> => {
      // helper
      const interop = new Interop(dotNetRef);
      // preprocess values
      let id: string|null = null;
      const handlers: Record<string, boolean> = {};
      const opts: Record<string, any> = {};
      for (const key of Object.keys(options)) {
          const value = options[key];
          // eliminate null values
          if (null === value || undefined === value) {
            continue;
          }
          // handle special cases
          if ('target' === key) {
            if (value instanceof HTMLElement) {
              if (!value.id) {
                value.id = supplyId();
              }
              id = value.id;
            } else {
              throw new Error('target must be a valid html element');
            }
          }
          if ('events' === key) {
            for (const name of Object.keys(value)) {
              const hx = value[name];
              if (hx) {
                handlers[name] = !!(handlers[name] || hx);
              }
            }
            continue;
          }
          if ('init_instance_callback' === key) {
            opts[key] = (ed: TinyMCEEditor) => {
              interop.initialize(ed.id);
            };
            continue;
          }
          if ('menu' === key || 'valid_classes' === key || 'valid_styles' === key) {
            if (!Object.keys(value).length) {
              continue;
            }
          }
          // passthrough
          opts[key] = value;
      }
      // handlers
      opts.setup = (ed: TinyMCEEditor) => {
        interop.ready(ed.id); // NOTE: no await
        ed.on('init', async () => {
          if (!opts.init_instance_callback) {
            await interop.initialize(ed.id);
          }
          const value = await interop.getValue();
          ed.setContent(value || '', { format: 'html' });
        });
        ed.on('change input undo redo', async () => {
          const currentValue = ed.getContent({ format: 'html' });
          await interop.updateValue(currentValue);
        });
        if (Object.keys(handlers).length) {
          for (const evt of Object.keys(handlers).filter(key => handlers[key])) {
            ed.on(evt, async () => {
              interop.notify(evt);
            });
          }
        }
      };
      console.warn(opts);
      window.tinymce.init(opts);
      return Promise.resolve();
    },
    async destroy(id: string): Promise<void> {
      const ed = window.tinymce.get(id);
      if (ed) {
        ed.destroy();
        const el = document.getElementById(id);
        if (el) {
          el.id = '';
        }
      }
    },
    check(id: string) {
      const ed = window.tinymce.get(id);
      return !!(ed);
    }
  };
  */

  // OEMBED ************************************************************************************************************

  const regexScript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

  const fbAccessToken = '';

  let pinstgrm: Promise<void>|null = null;

  const loadIntsgrm = () => {
    if (!pinstgrm) {
      pinstgrm = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.onload = () => {
          const wait = (nTry: number) => {
            if ((<any> window).instgrm) {
              resolve();
            } else if (nTry > 10) {
              reject('failed to load instagram');
            } else {
              setTimeout(() => wait(nTry + 1), nTry * 50);
            }
          };
          wait(1);
        };
        script.src = '//platform.instagram.com/en_US/embeds.js';
        document.head.appendChild(script);
      });
    }
    return pinstgrm;
  };

  let pfb: Promise<void>|null = null;

  const loadFb = () => {
    if (!pfb) {
      pfb = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.onload = () => {
          const wait = (nTry: number) => {
            if ((<any> window).instgrm) {
              resolve();
            } else if (nTry > 10) {
              reject('failed to load instagram');
            } else {
              setTimeout(() => wait(nTry + 1), nTry * 50);
            }
          };
          wait(1);
        };
        script.src = '//connect.facebook.net/en_US/sdk.js';
        document.head.appendChild(script);
      });
    }
    return pfb;
  };

  window.MVM.initOEmbed = async (target, rawUri, jsonp?: boolean) => {
    if (!rawUri) { return; }
    const uri = window.Uri.from(rawUri);
    uri.queryParams.width = String(target.clientWidth);
    uri.queryParams.maxwidth = String(target.clientWidth);
    uri.queryParams.maxheight = '220';
    let onReady = () => { /* noop */ };
    if (-1 !== uri.host!.search(/facebook/) || -1 !== uri.host!.search(/instagram/)) {
      uri.queryParams.access_token = fbAccessToken;
      uri.queryParams.maxwidth = '320';
      uri.queryParams.omitscript = 'true';
      if ('string' === typeof uri.queryParams.url && -1 !== uri.queryParams.url.search(/instagr\.?am/)) {
        onReady = () => {
          loadIntsgrm().then(() => {
            (<any> window).instgrm.Embeds.process();
          });
        };
      } else {
        uri.queryParams.useiframe = 'true';
      }
    }
    let data: any;
    if (jsonp) {
      const fn = `jsonp_${Date.now()}_${Math.round(Math.random() * 10000)}`;
      uri.queryParams.callback = fn;
      uri.queryParams.jsoncallback = fn;
      data = await new Promise<any>((resolve, reject) => {
        (<any> window)[fn] = resolve;
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.src = uri.href;
        document.head.appendChild(script);
      });
      if ('string' === typeof data.html && -1 === data.html.indexOf('<script')) {
        // remove script tags
        data.html = data.html.replace(regexScript, '');
      }
    } else {
      const response = await fetch(uri.href);
      if (!response.ok) {
        console.error(`failed to load oembed data from ${uri.href}`);
        return;
      }
      data = await response.json();
    }
    if (!data || !data.html) {
      console.error(`got invalid oembed data from ${uri.href}`);
      return;
    }
    target.innerHTML = data.html;
    onReady();
  };

  // GLOBAL SCROLL *****************************************************************************************************

  const scrollHandlers: DotNetRef[] = [];
  let pageY = 0;
  let lastPageY = -1;

  const scrollHandler = () => {
    window.requestAnimationFrame(scrollHandler);
    if (pageY !== lastPageY) {
      lastPageY = pageY;
      for (const handler of scrollHandlers) {
        // NOTE: intentionally not awaited
        handler.invokeMethodAsync('JsOnScroll', pageY);
      }
    }
  };
  window.requestAnimationFrame(scrollHandler);

  window.addEventListener('scroll', () => {
    pageY = window.pageYOffset;
  }, false);

  window.MVM.registerScrollHandler = (cbObj: DotNetRef) => {
    scrollHandlers.push(cbObj);
  };
  window.MVM.unregisterScrollHandler = (cbObj: DotNetRef) => {
    const index = scrollHandlers.indexOf(cbObj);
    if (-1 === index) {
      scrollHandlers.splice(index, 1);
    }
  };

  // DOWNLOAD **********************************************************************************************************

  // Convert a base64 string to a Uint8Array. This is needed to create a blob object from the base64 string.
  // The code comes from: https://developer.mozilla.org/fr/docs/Web/API/WindowBase64/D%C3%A9coder_encoder_en_base64
  const b64ToUint6 = (nChr: number) => {
    return nChr > 64 && nChr < 91 ? nChr - 65 : nChr > 96 && nChr < 123 ? nChr - 71 : nChr > 47 && nChr < 58 ? nChr + 4 : nChr === 43 ? 62 : nChr === 47 ? 63 : 0;
  };

  const base64DecToArr = (sBase64: string, nBlocksSize?: number) => {
    const sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, '');
    const nInLen = sB64Enc.length;
    // tslint:disable-next-line: no-bitwise
    const nOutLen = nBlocksSize ? Math.ceil((nInLen * 3 + 1 >> 2) / nBlocksSize) * nBlocksSize : nInLen * 3 + 1 >> 2;
    const taBytes = new Uint8Array(nOutLen);

    for (let nMod3: number, nMod4: number, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
      // tslint:disable-next-line: no-bitwise
      nMod4 = nInIdx & 3;
      // tslint:disable-next-line: no-bitwise
      nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
      if (nMod4 === 3 || nInLen - nInIdx === 1) {
        for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
          // tslint:disable-next-line: no-bitwise
          taBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
        }
        nUint24 = 0;
      }
    }
    return taBytes;
  };

  window.MVM.downloadAs = (filename: string, contentType: string, content: string) => {
    // Blazor marshall byte[] to a base64 string, so we first need to convert the string (content) to a Uint8Array to create the File
    const data = base64DecToArr(content);

    // Create the URL
    const file = new File([data], filename, { type: contentType });
    const exportUrl = URL.createObjectURL(file);

    // Create the <a> element and click on it
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = exportUrl;
    a.download = filename;
    a.target = '_blank';
    a.click();

    // schedule cleanup
    setTimeout(() => {
      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }
    }, 15000);

    // We don't need to keep the url, let's release the memory
    URL.revokeObjectURL(exportUrl);
  };

  // DROP DOWN *********************************************************************************************************

  const ownerMap = new WeakMap<HTMLElement, { owner: HTMLElement; settings?: DropDownSettings }>();

  const reposition = (e: HTMLElement, { owner, settings }: { owner: HTMLElement; settings?: DropDownSettings }) => {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const rect = owner.getBoundingClientRect();
    // vertical position + height;
    if (rect.top > (vh - rect.bottom)) {
      // open above the owner reversed
      e.setAttribute('reversed', '');
      e.style.top = 'auto';
      e.style.left = `${rect.left}px`;
      e.style.bottom = `${vh - rect.top}px`;
      if (settings && settings.fixHeight) {
        e.style.height = 'number' === typeof settings.fixHeight ? `${settings.fixHeight}px` : settings.fixHeight;
        e.style.overflow = 'visible';
      } else {
        e.style.maxHeight = `${rect.top}px`;
        e.style.removeProperty('overflow');
      }
    } else {
      e.removeAttribute('reversed');
      e.style.top = `${rect.bottom}px`;
      e.style.bottom = 'auto';
      if (settings && settings.fixHeight) {
        e.style.height = 'number' === typeof settings.fixHeight ? `${settings.fixHeight}px` : settings.fixHeight;
        e.style.overflow = 'visible';
      } else {
        e.style.maxHeight = `${vh - rect.bottom}px`;
        e.style.removeProperty('overflow');
      }
    }
    // horizontal position
    const halign = settings && settings.halign;
    if (!halign || halign === 'left') {
      e.style.left = `${rect.left}px`;
      e.style.right = 'auto';
    } else if (halign === 'right') {
      e.style.right = `${vw - rect.right}px`;
      e.style.left = 'auto';
    } else {
      e.style.left = `${rect.left + (rect.width / 2)}px`;
    }
  };

  window.MVM.showDropDown = (e: HTMLElement, owner?: HTMLElement, settings?: DropDownSettings) => {
    if (owner) {
      ownerMap.set(e, { owner, settings });
      reposition(e, { owner, settings });
    }
    e.hidden = false;
  };
  window.MVM.hideDropDown = (e: HTMLElement) => {
    e.hidden = true;
  };
  window.MVM.updateDropDown = (e: HTMLElement) => {
    const owner = ownerMap.get(e);
    if (owner && !e.hidden) {
      reposition(e, owner);
    }
  };
  window.MVM.setValue = (e: HTMLInputElement, value: string) => {
    e.value = value;
  };

  // DB ****************************************************************************************************************

  interface Entry {
    readonly key: number;
    readonly data: string;
    readonly expiry: number;
  }

  class DbWrapper {
    constructor(private readonly db: IDBDatabase, private readonly storeName: string) { }
    async putAsync(key: number, data: string) {
      const tx = this.db.transaction(this.storeName, 'readwrite');
      const store = tx.objectStore(this.storeName);
      const txp = new Promise<void>((resolve, reject) => {
        tx.addEventListener('complete', () => resolve());
        tx.addEventListener('error', () => reject(tx.error));
        tx.addEventListener('abort', () => reject('aborted'));
      });
      const putp = new Promise<void>((resolve, reject) => {
        const req = store.put({ key, data, expiry: Date.now() + (1000 * 60 * 5) });
        req.addEventListener('success', () => resolve());
        req.addEventListener('error', () => reject(req.error));
      });
      await putp;
      await txp;
    }
    async lookupAsync(key: number) {
      const tx = this.db.transaction(this.storeName, 'readonly');
      const store = tx.objectStore(this.storeName);
      const txp = new Promise<void>((resolve, reject) => {
        tx.addEventListener('complete', () => resolve());
        tx.addEventListener('error', () => reject(tx.error));
        tx.addEventListener('abort', () => reject('aborted'));
      });
      const getp = new Promise<string|null>((resolve, reject) => {
        const req : IDBRequest<Entry> = store.get(key);
        req.addEventListener('success', () => {
          if (req.result && req.result.expiry >= Date.now()) {
            resolve(req.result.data);
          } else {
            resolve(null);
          }
          req.addEventListener('error', () => reject(req.error));
        });
      });
      const res = await getp;
      await txp;
      return res;
    }
  }

  window.MVM.openDb = (name: string) => new Promise<any>((resolve, reject) => {
    const req = window.indexedDB.open(name);
    req.addEventListener('upgradeneeded', () => {
      const db = req.result;
      const store = db.createObjectStore('default', { keyPath: 'key', autoIncrement: false });
      store.createIndex('expiry', 'expiry', { unique: false });
    });
    req.addEventListener('blocked', () => reject('blocked'));
    req.addEventListener('error', () => reject(req.error));
    req.addEventListener('success', () => {
      resolve(new DbWrapper(req.result, 'default'));
    });
  });

}());